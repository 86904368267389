<template>
  <div class="main">
    <Loading v-show="state === ViewState.Loading" />
    <div v-if="state === ViewState.Loaded">
      <!-- 避難所開設中 -->
      <div v-if="response.home.disaster" class="alert alert-danger text-center p-4" role="alert">
        <p class="alert-heading h3"><b>避難所開設中</b></p>
        <hr>
        <p class="mb-1"><b>{{ response.home.disaster.title }}</b></p>
        <p class="small"><b>{{ formatDate(response.home.disaster.start_at) }} 〜</b></p>
        <b-row class="text-center">
          <b-col>
            <b-button variant="danger" :to="{ name: 'disaster_current' }">避難所の確認・事前登録はこちら</b-button>
          </b-col>
        </b-row>
      </div>
      <!-- 現在 避難所に関する情報はありません -->
      <div v-else>
        <b-card>
          <p class="card-text text-center my-5"><b>現在、避難所に関する情報はありません</b></p>
        </b-card>
      </div>
      <!-- お知らせ -->
      <div class="mt-5">
        <p class="h4 mb-4 text-center">お知らせ</p>
        <div v-if="response.home.notices.length" class="list-group mt-4">
          <div
            v-for="(notice, index) in response.home.notices"
            :key="index"
            class="list-group-item list-group-item-action flex-column align-items-start"
          >
            <router-link :to="{ name: 'notice_detail', params: { id: notice.id } }">
              <div class="row">
                <div class="col">
                  <p class="mb-0">{{ notice.title }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>
          <p class="h5 text-muted text-center">現在お知らせはありません</p>
        </div>
      </div>
    </div>
    <div v-if="state === ViewState.Fatal">
      <div class="text-center mt-5">
        <h4>{{ state.title }}</h4>
        <p>{{ state.message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import DateUtils from '@/commons/utils/DateUtils'

const ViewState = {
  Default: {},
  Loading: {},
  Loaded: {},
  Fatal: { title: 'エラー', message: '恐れ入りますが、しばらく経ってから再度お試しください' },
}

export default {
  mixins : [ DateUtils ],
  data() {
    return {
      ViewState: ViewState,
      state: ViewState.Default,
      response: {
        home: {
          disaster: null,
          notices: [],
        }
      },
    };
  },
  components: {
    Loading,
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.state = ViewState.Loading
      await this.axios.get("/api/home").then((response) => {
        this.response.home = response.data
        this.state = ViewState.Loaded
      }).catch(() => {
        this.state = ViewState.Fatal
      })
    },
    onMapMarkerClick(disaster_shelter) {
      this.active_shelter = disaster_shelter
    },
  }
}
</script>

<style scoped>
.main {
  margin: 20px;
}
</style>
