var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("Loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.state === _vm.ViewState.Loading,
            expression: "state === ViewState.Loading"
          }
        ]
      }),
      _vm.state === _vm.ViewState.Loaded
        ? _c("div", [
            _vm.response.home.disaster
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-danger text-center p-4",
                    attrs: { role: "alert" }
                  },
                  [
                    _vm._m(0),
                    _c("hr"),
                    _c("p", { staticClass: "mb-1" }, [
                      _c("b", [
                        _vm._v(_vm._s(_vm.response.home.disaster.title))
                      ])
                    ]),
                    _c("p", { staticClass: "small" }, [
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.formatDate(_vm.response.home.disaster.start_at)
                          ) + " 〜"
                        )
                      ])
                    ]),
                    _c(
                      "b-row",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "danger",
                                  to: { name: "disaster_current" }
                                }
                              },
                              [_vm._v("避難所の確認・事前登録はこちら")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("b-card", [
                      _c("p", { staticClass: "card-text text-center my-5" }, [
                        _c("b", [
                          _vm._v("現在、避難所に関する情報はありません")
                        ])
                      ])
                    ])
                  ],
                  1
                ),
            _c("div", { staticClass: "mt-5" }, [
              _c("p", { staticClass: "h4 mb-4 text-center" }, [
                _vm._v("お知らせ")
              ]),
              _vm.response.home.notices.length
                ? _c(
                    "div",
                    { staticClass: "list-group mt-4" },
                    _vm._l(_vm.response.home.notices, function(notice, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "list-group-item list-group-item-action flex-column align-items-start"
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "notice_detail",
                                  params: { id: notice.id }
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(_vm._s(notice.title))
                                  ])
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("div", [
                    _c("p", { staticClass: "h5 text-muted text-center" }, [
                      _vm._v("現在お知らせはありません")
                    ])
                  ])
            ])
          ])
        : _vm._e(),
      _vm.state === _vm.ViewState.Fatal
        ? _c("div", [
            _c("div", { staticClass: "text-center mt-5" }, [
              _c("h4", [_vm._v(_vm._s(_vm.state.title))]),
              _c("p", [_vm._v(_vm._s(_vm.state.message))])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "alert-heading h3" }, [
      _c("b", [_vm._v("避難所開設中")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }